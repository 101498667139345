<template>
    
    <form role="form" @submit="onFormSubmit" name="shop-switch-form">
      
      <Select
        v-if="listShops.length > 0"
        :label="$t('cms.site.switch.label')"
        :placeholder="$t('cms.site.switch.select_placeholder')"
        :selectOptions="{
          options: listShops,
          getOptionLabel : option => option.name
        }"
        v-bind:value.sync="form.shop" 
        :labelAsColumn="false"
      />
      
      <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('cms.site.switch.button') }}</button>
    </form>
</template>

<style>
  form[name=shop-switch-form] {
    display: flex;
  }

  form[name=shop-switch-form] > div {
    display: flex;
    margin-bottom: 0;
    flex-grow:1;
  }

  form[name=shop-switch-form] > div > label {
    margin-right:10px;
    line-height: 36px;
    margin-bottom: 0px;
  }

  form[name=shop-switch-form] > div > div {
    flex-grow:1;
  }

  form[name=shop-switch-form] > button {
    margin-left:10px;
  }
</style>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Select from '@fwk-client/components/panels/input/Select.vue';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';

import { useShopAdmin } from '../../composables/useShopAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      Select
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { shops, selectedShop, selectShop } = useShopAdmin(props, context);

      const form:any = reactive({
        shop: selectedShop ? selectedShop.value.shop : undefined
      })
    
      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=shop-switch-form] button' );
        laddaSubmit = Ladda.create(button!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        laddaSubmit!.start();
        selectShop(form.shop._id).then(() => {
          laddaSubmit!.stop();
        });
      }

      const listShops = computed(() => {
        return shops.value.map((shop:any) => {
          return shop.shop;
        })
      })

      return { 
        listShops,
        form,
        selectedShop,
        onFormSubmit
      }
    }
})
</script>